import React from 'react';
// import './About.css'; // 如果有需要引入相关样式文件

const About = () => {
  return (
    <div className="about-tab">
      <h2>About Our Product</h2>
      <p>
        - Finalized Score Calculation: Each predicted score is primarily based on the closing price. <br />
        - Historical Score Accuracy: When checking historical scores, only data available before the specific date is used. For example, if you check the score for 8/15/2023, we only utilize data up to 8/14/2023—never data from 8/16/2023. This ensures the accuracy of the "prediction." <br />
        - Real-Time Score Updates: If you're checking today's score, it will be updated every hour. Typically, if there are no significant price fluctuations, the score should remain stable. We recommend checking the score 30 minutes before the market closes for the most accurate reflection. <br />
        - Score Validity: The score is valid for two weeks. Once a significant rise or dip occurs, the score should be considered invalid. <br />
        - How to use: Input a stock code and select a date, then click Analyze button. <br />
        - Popular stocks: NVDA, META, AMZN, AAPL, QQQ... <br />
        - Short-Term Trend Analysis: Our tool identifies price changes within a 2-week period from the current query date, making it ideal for short-term trend analysis. <br />
        - Model Accuracy: The model has an overall accuracy of around 85%, tested primarily on the top 100 most traded US stocks. <br />
        - Backtesting: We use extensive data and allow users to backtest the model's performance with historical data. <br />
        - For any product questions or business collaboration, please contact us at stock@airacle.tech.
      </p>
      <p>
        Details: <a href="https://ec4dm2lbbcs.larksuite.com/wiki/ARENwECKQiuzzjk28yuuWv1vsBc" target="_blank" rel="noopener noreferrer">User Manual</a>
      </p>
    </div>
  );
};

export default About;
