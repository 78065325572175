import React from "react";
import {Container, Row, Col} from "reactstrap";

import Loading from "../components/Loading";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";


export const ProfileComponent = () => {
    const {user} = useAuth0();

    return (
        <Container className="mb-5">
            <Row className="align-items-center profile-header mb-5 text-center text-md-left">
                <Col md={2}>
                    <img
                        src={user.picture}
                        alt="Profile"
                        className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
                    />
                </Col>
                <Col md>
                    <h2>{user.name}</h2>
                    <p className="lead text-muted">{user.email} {user.email_verified ?
                        <span className="badge bg-success text-white">Verified</span> :
                        <span className="badge bg-danger text-white">NO Verified</span>}</p>
                </Col>
            </Row>
        </Container>
    );
};

export default withAuthenticationRequired(ProfileComponent, {
    onRedirecting: () => <Loading/>,
});
