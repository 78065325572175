import { useAuth0 } from "@auth0/auth0-react";
import { Button, Container, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import axios from 'axios';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Tooltip } from 'chart.js/auto';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import API_BASE_URL from './const.js';
import 'chartjs-adapter-date-fns';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, BarElement, Tooltip, Legend);

const StockSignalsDashboard = () => {
    const [stockCodes, setStockCodes] = useState([]);
    const [selectedStocks, setSelectedStocks] = useState([]);
    const [stockDataMap, setStockDataMap] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchStockCodes = async () => {
            try {
                console.log("Fetching stock codes...", API_BASE_URL);
                const response = await axios.get(`${API_BASE_URL}/api/check_history_stocks`);
                setStockCodes(response.data.sort());
            } catch (error) {
                console.error("Error fetching stock codes", error);
            }
        };

        fetchStockCodes();
    }, []);

    const { getAccessTokenSilently } = useAuth0();

    const handleSearch = async () => {
        if (selectedStocks.length > 0) {
            setIsLoading(true);
            const token = await getAccessTokenSilently();
            try {
                const response = await axios.get(`${API_BASE_URL}/api/check_history_stocks/${selectedStocks.join(',')}`,
                    { headers: { 'Authorization': `Bearer ${token}` } });
                setStockDataMap(response.data);
                console.log(response.data);
            } catch (error) {
                console.error("Error querying stock data", error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleStockChange = (event) => {
        const {
            target: { value },
        } = event;
        // Limit to a maximum of 3 stocks
        setSelectedStocks(
            typeof value === 'string' ? value.split(',') : value.slice(0, 3)
        );
    };

    const getPointBackgroundColor = (data) => {
        if (data.BuyOrSell === 1) return 'green'; // Buy
        if (data.BuyOrSell === 0) return 'red'; // Sell
        if (data.BuyOrSell === -1) return 'black'; // Neutral
        return 'gray'; // Missing/Unpredicted data
    };

    const getPointStyle = (data) => {
        if (data.BuyOrSell === 1 || data.BuyOrSell === 0) return 'triangle';
        return 'circle';
    };

    const getPointRotation = (data) => {
        if (data.BuyOrSell === 1) return 0;
        if (data.BuyOrSell === 0) return 180;
        return 0;
    };

    // 更新图表数据生成逻辑
    const generateChartData = () => {
        const datasets = selectedStocks.flatMap(stock => [
            {
                type: 'line',
                label: `${stock} Closing Price`,
                data: stockDataMap[stock]?.map((data) => ({ x: data.date, y: data.close })) || [],
                borderColor: getStockColor(stock),
                backgroundColor: getStockColor(stock),
                borderWidth: 2,
                fill: false,
                yAxisID: 'y',
                pointBackgroundColor: stockDataMap[stock]?.map((data) => getPointBackgroundColor(data)) || [],
                pointStyle: stockDataMap[stock]?.map((data) => getPointStyle(data)) || [],
                pointRotation: stockDataMap[stock]?.map((data) => getPointRotation(data)) || [],
                pointRadius: stockDataMap[stock]?.map((data) => (data.BuyOrSell !== null || data.profit === null ? 8 : 4)) || []
            },
            {
                type: 'bar',
                label: `${stock} Buy Score`,
                data: stockDataMap[stock]?.map((data) => ({ x: data.date, y: data.BuyScore })) || [],
                backgroundColor: `${getStockColor(stock)}80`,
                yAxisID: 'y1',
                barThickness: 6,
                barPercentage: 0.5,
                categoryPercentage: 0.5,
            }
        ]);

        return {
            datasets: datasets
        };
    };

    // 为每只股票分配不同的颜色
    const getStockColor = (stock) => {
        const colors = ['#FF6384', '#36A2EB', '#FFCE56'];
        return colors[selectedStocks.indexOf(stock) % colors.length];
    };

    const chartOptions = {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    generateLabels: (chart) => {
                        const original = ChartJS.defaults.plugins.legend.labels.generateLabels(chart);
                        return [
                            ...original,
                            {
                                text: 'Buy',
                                fillStyle: 'green',
                                pointStyle: 'triangle',
                            },
                            {
                                text: 'Sell',
                                fillStyle: 'red',
                                pointStyle: 'triangle',
                            },
                            {
                                text: 'Neutral',
                                fillStyle: 'black',
                                pointStyle: 'circle',
                            },
                            {
                                text: 'Missing/Unpredicted',
                                fillStyle: 'gray',
                                pointStyle: 'circle',
                            }
                        ];
                    }
                }
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += context.parsed.y.toFixed(2);
                        }
                        return label;
                    }
                }
            }
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day'
                }
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                title: {
                    display: true,
                    text: 'Stock Price'
                }
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
                grid: {
                    drawOnChartArea: false,
                },
                min: 0,
                max: 1,
                title: {
                    display: true,
                    text: 'Buy Score'
                }
            }
        }
    };

    // 添加清空选择的函数
    const handleClearSelection = () => {
        setSelectedStocks([]);
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Stock Signals Dashboard
            </Typography>
            <Typography variant="body2" gutterBottom>
                All signals predicted by our AI over the past month.
            </Typography>
            <br />
            <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth sx={{ height: '100%' }}>
                        <InputLabel id="stock-select-label">Select Stocks (max 3)</InputLabel>
                        <Select
                            labelId="stock-select-label"
                            multiple
                            value={selectedStocks}
                            label="Select Stocks (max 3)"
                            onChange={handleStockChange}
                            renderValue={(selected) => selected.join(',')}
                            sx={{ height: '100%' }}
                        >
                            {stockCodes.map((stock) => (
                                <MenuItem key={stock} value={stock}>
                                    {stock}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={2} sx={{ display: 'flex' }}>
                    <Button
                        variant="outlined"
                        onClick={handleClearSelection}
                        disabled={selectedStocks.length === 0|| isLoading}
                        fullWidth
                        sx={{ height: '100%' }}
                    >
                        {isLoading ? 'Loading...' : 'Clear'}
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} sx={{ display: 'flex' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSearch}
                        disabled={selectedStocks.length === 0 || isLoading}
                        fullWidth
                        sx={{ height: '100%' }}
                    >
                        {isLoading ? 'Loading...' : 'Search'}
                    </Button>
                </Grid>
            </Grid>
            <br />
            {Object.keys(stockDataMap).length > 0 && (
                <Line data={generateChartData()} options={chartOptions} />
            )}
        </Container>
    );
};

export default StockSignalsDashboard;
