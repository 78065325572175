import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

const Hero = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <div className="text-center hero my-5">
      {isAuthenticated ? (
        <p>Now click on the <b>stock</b> menu to use it!</p>
      ) : (
        <>
          <h2 className="mb-4">Welcome! Please log in.</h2>
        </>
      )}
      <div>
        <h1><a href="https://www.When2buy.ai" target="_blank" rel="noopener noreferrer">When2buy.ai</a></h1>
        <h2>Latest Model (Will release gradually weekly)</h2>
        <ul>
          <li>We plan to release a 1-day model in the coming 2 weeks, you'll be able to use it for daily trading and get feedback immediately</li>
          <li>Trading Capability: Now facilitates actual trading.</li>
          <li>Flexible Predictions: Provides flexible predictions across various stocks and time frames.</li>
          <li>
            Achieves similar accuracy for nearly any investment assets traded by humans, usable with time resolutions from 8 minutes to 365 days. It can predict prices and execute trades.
          </li>
          <li>Model Preview: <a href="https://www.bilibili.com/video/BV14tv6ezE7n/?share_source=copy_web&vd_source=21920bbc00c9b3ea8b0c0424c42d3762" target="_blank" rel="noopener noreferrer">Bilibili Video</a></li>

        </ul>
      </div>
    </div>
  );
};

export default Hero;
